<template>
  <div class="flix-navbar flix-navbar-small">
    <div class="flix-container" style="margin-bottom: 30px;">
      <div class="flix-row">
        <div class="flix-col-sm-3 flix-col-xs-12">
          <ul class="flix-list-unstyled flix-clear-margins">
            <li class="flix-html-li"><router-link @click.native="scrollToTop" class="flix-html-a" :to="{name: 'frontend', params: {page: 'support-chat'}}">{{ $tc("message.supportChat", 1) }}</router-link></li>
          </ul>
        </div>
        <div class="flix-col-sm-3 flix-col-xs-12">
          <ul class="flix-list-unstyled flix-clear-margins">
            <li class="flix-html-li"><router-link @click.native="scrollToTop" class="flix-html-a" :to="{name: 'frontend', params: {page: 'terms'}}">{{ $tc("message.terms", 1) }}</router-link></li>
          </ul>
        </div>
        <div class="flix-col-sm-3 flix-col-xs-12">
          <ul class="flix-list-unstyled flix-clear-margins">
            <li class="flix-html-li"><router-link @click.native="scrollToTop" class="flix-html-a" :to="{name: 'frontend', params: {page: 'imprint'}}">{{ $tc("message.imprint", 1) }}</router-link></li>
          </ul>
        </div>
        <div class="flix-col-sm-3 flix-col-xs-12">
          <ul class="flix-list-unstyled flix-clear-margins">
            <li class="flix-html-li"><router-link @click.native="scrollToTop" class="flix-html-a" :to="{name: 'frontend', params: {page: 'privacy'}}">{{ $tc("message.privacy", 1) }}</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
  <style lang="sass" scoped>
    .flix-navbar
      background-color: #eee
      border: 0
      font-size: 12pt
      margin-top: 50px
      transition: all 0.5s ease-in-out
      &:hover
        box-shadow: none
      .flix-html-h3
        text-transform: uppercase
      li.flix-html-li
        line-height: 1.2em

      .flix-html-a
        color: inherit

      .router-link-exact-active
        font-weight: bold

    @media (max-width: 500px)
      .flix-col-md-4
        height: inherit
      .editBtns
        a
          display: block
        a:first-of-type
          margin-right: 0
          margin-bottom: 10px
  </style>
